<template>
    <div class="button_group" style="margin-top: 10px;">
        <router-link class="btn02 bw2" tag="button"  :to="{path:'/leisure/bet365_goldenhill', query: {t: new Date().getTime()}}">골든힐</router-link>
        <router-link class="btn02 bw2" tag="button"  :to="{path:'/leisure/bet365_hillside', query: {t: new Date().getTime()}}">힐사이드</router-link>
    </div>
</template>

<script>
    export default {
        name: "LeisureBet365DogLinks"
    }
</script>

<style scoped>

</style>